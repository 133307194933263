import React, { Suspense, useEffect } from "react";
import "./HomePage.css";
import { HomeHero, HomeOurStory } from "../../container";
// import HomeInsight from "../../container/homeinsight/HomeInsight";
// import HomeStayAhed from "../../container/homeStayahed/HomeStayAhed";
// import HomeOurProject from "../../container/homeourproject/HomeOurProject";
// import HomeRatings from "../../container/homeratings/HomeRatings";
// import Whatsets from "../../container/Whatsets/Whatsets";
// import Teamup from "../../container/TeamUp/Teamup";
// import Ready from "../../container/Ready/Ready";

const HomeRatings = React.lazy(() => import("../../container/homeratings/HomeRatings"))
const Whatsets = React.lazy(() => import("../../container/Whatsets/Whatsets"))
const HomeOurProject = React.lazy(() => import("../../container/homeourproject/HomeOurProject"))
const HomeStayAhed = React.lazy(() => import("../../container/homeStayahed/HomeStayAhed"))
const Teamup = React.lazy(() => import("../../container/TeamUp/Teamup"))
const Ready = React.lazy(() => import("../../container/Ready/Ready"))
const HomeInsight = React.lazy(() => import("../../container/homeinsight/HomeInsight"))

const HomePage = () => {
  useEffect(() => {
    window.scrollTo({top: 0,behavior: "instant"})
   }, [])


  //  this for test github
   
  return (
    <div className="section_main_wrapp">
        <HomeHero/>
        <HomeOurStory />
        <Suspense fallback={<></>}>
          <HomeRatings/>
          <Whatsets />
          <HomeOurProject/>
          <HomeStayAhed/>
          <Teamup />
          <Ready />
          <HomeInsight/>
        </Suspense>
    </div>
  );
};

export default HomePage;
